import { Pipe, PipeTransform } from "@angular/core"
import { NGXLogger as LoggerService } from "ngx-logger";
import { EssTranslationService } from "./translation.service";

@Pipe({
    standalone: true,
    name: 'trans',
})
export class TranslatePipe implements PipeTransform {
    constructor(private essTrans: EssTranslationService) { }
    /**
    *
    * @param value : either 3 elements (e.g. "advanced.home.title")  or 2 elements  (e.g. "home.title")
    * @param scope : e.g "page", "templates"
    * @param templateFallBackHierarchy e.g. ["advanced", "schedule"]
    * @returns
    */
    async transform(keys: string, scope?: string, templateFallBackHierarchy?: string[]) {
        return essTranlatePipeTransform(this.essTrans, keys, scope, templateFallBackHierarchy)
    }
}


/**
 *
 * @param keys :  e.g. "home.title"
 * @returns
 */
@Pipe({
    standalone: true,
    name: 'transPage',
})
export class TranslateLandingPagePipe implements PipeTransform {
    constructor(private essTrans: EssTranslationService) { }
    async transform(keys: string) {
        return essTranlatePipeTransform(this.essTrans, keys, 'page', ['advanced'])
    }
}

/**
 *
 * @param keys :  e.g. "home.title"
 * @returns
 */
@Pipe({
    standalone: true,
    name: 'transApp',
})
export class TranslateAppPipe implements PipeTransform {
    constructor(private essTrans: EssTranslationService) { }
    async transform(keys: string) {
        return essTranlatePipeTransform(this.essTrans, keys, 'app')
    }
}

/**
 *
 * @param keys :  e.g. "home.title"
 * @returns
 */
@Pipe({
    standalone: true,
    name: 'transUser',
})
export class TranslateUserPipe implements PipeTransform {
    constructor(private essTrans: EssTranslationService) { }
    async transform(keys: string) {
        return essTranlatePipeTransform(this.essTrans, keys, 'user')
    }
}

/**
 *
 * @param essTrans
 * @param value : either 3 elements (e.g. "advanced.home.title")  or 2 elements  (e.g. "home.title")
 * @param scope : e.g "page", "templates"
 * @param templateFallBackHierarchy e.g. ["advanced", "schedule"]
 * @returns
 */
export function essTranlatePipeTransform(essTrans: EssTranslationService, value: string, scope?: string, templateFallBackHierarchy?: string[]) {
    let template, keys: string;
    const valueSplit = value.split('.');
    if (valueSplit.length === 3) {
        template = valueSplit[0];
        keys = valueSplit[1] + "." + valueSplit[2];
        if (templateFallBackHierarchy) {
            templateFallBackHierarchy = [...templateFallBackHierarchy] // to not modify the param of the function
            templateFallBackHierarchy.push(template)
        } else {
            templateFallBackHierarchy = [template]
        }
    } else if (valueSplit.length === 2) { // not template provided
        keys = valueSplit[0] + "." + valueSplit[1];
    }
    else {
        return null;
    }
    return essTrans.translate(keys, scope, templateFallBackHierarchy);
}
