import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslationDepricatedService } from './translation.depricated.service';
import { DOCUMENT } from '@angular/common';
import { AlternateHrefService } from '../../locale/alternate-href.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, Subscription } from 'rxjs';
import { routesSeo as routesSeoEn } from '../../locale/en/routes-seo';
import { GoogleAnalyticsService } from './google-analytics.service';
;



export interface HRef {
    href: string
}

export const languageCodes = ["en", "de", "it", "fr", "es", "pt"]

export interface HrefLangMapping {
    en?: HRef,
    de?: HRef,
    it?: HRef,
    es?: HRef,
    pt?: HRef,
    fr?: HRef,
}

@Injectable({
    providedIn: 'root',
})
export class RouteContextService {
    private _id: string;
    private renderer: Renderer2;
    private linkElements = [];
    private noIndexElement;
    private alternatUrls: string[] = [];
    public appContext = false;
    public isFullScreen = false;
    private subscribtionRouteData: Subscription;
    private subscribtionRouterNavigationEnd: Subscription;
    constructor(
        private htmlTitle: Title,
        private htmlMeta: Meta,
        private logger: LoggerService,
        private trans: TranslationDepricatedService,
        rendererFactory: RendererFactory2, // we cannot inject the renderer directly in a service
        @Inject(DOCUMENT) private document: any,
        private alternateHrefService: AlternateHrefService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private googleAnalyticsService: GoogleAnalyticsService
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }
    get id(): string {
        return this._id;
    }

    get title() {
        return this.trans.getShortTitle(this.id);
    }

    public subscribe() {
        this.subscribtionRouteData = this.subscribeRouteData()
        this.subscribtionRouterNavigationEnd = this.subscribeRouterNavigationEnd()
    }

    public unsubscribe() {
        this.subscribtionRouteData.unsubscribe()
        this.subscribtionRouterNavigationEnd.unsubscribe()
    }

    private subscribeRouterNavigationEnd(): Subscription {
        return this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.logger.log('subRouterEventsForBlog', event)
                this.setFullScreen(event);
                this.setAppContext(event);
            }
        });
    }



    get isVotePage(): boolean {
        //  this.logger.log('isVotePage', this.id)
        if (this.id === 'vote') return true
        return false;
    }

    private setAppContext(event): void {
        if (
            event.urlAfterRedirects.indexOf(routesSeoEn['editPoll']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['createPoll']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['myPolls']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['pollResponses']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['pollMatrix']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['vote']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['voteResponse']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['userProfile']) !== -1 ||
            event.urlAfterRedirects.indexOf(routesSeoEn['upgradeAnonymous']) !== -1
        ) {
            this.logger.log('no footer');
            this.appContext = true;
        } else {
            this.appContext = false;
        }
    }

    private setFullScreen(event): void {
        if (event.urlAfterRedirects.indexOf('full-screen') !== -1) {
            this.logger.log('fullscreen');
            this.isFullScreen = true;
        }
    }

    private subscribeRouteData(): Subscription {
        return this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((_route) => {
                    let route = <ActivatedRoute>_route;
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
                filter((route) => (<ActivatedRoute>route).outlet === 'primary'),
                mergeMap((route) => (<ActivatedRoute>route).data)
            )
            .subscribe((event) => {
                this.logger.log('subscribeRouteContext', event)
                this.handleRouteDateChange(event);
            });
    }

    private handleRouteDateChange(event) {
        this.alternatUrls = []
        this._id = event.id;
        this.logger.log('event', event);
        this.clearNoIndexElement();
        if (event.noIndex) {
            this.logger.log('event.noIndex')
            this.addNoIndex();
        }
        this.googleAnalyticsService.emitPageView(this.id, this.title)
        this.updateHtmlTitle(event.id);
        this.updateHtmlMetaDescription(event.id);
        this.removeAlternateHrefLangs();
        this.addAlternateHrefLangs(event.id);
    }

    private addAlternateHrefLangs(id: string) {
        this.logger.log('updateAlternateHrefLang', id);
        if (id !== "blog-post") {// blog posts are handled in blog module for perfommace reasons
            const alternates = this.alternateHrefService.getAlternateHrefLangs(id);
            alternates.forEach((alternate) => {
                this.logger.log('updateAlternateHrefLang alternate', alternate);
                this.insertHrefLang(alternate.langCode, alternate.url)
            });
            this.handleNoIndexCase();
        }

    }

    private insertHrefLang(langCode: string, url) {
        const linkElement = this.renderer.createElement('link');
        this.renderer.setAttribute(linkElement, 'rel', 'alternate');
        this.renderer.setAttribute(linkElement, 'hreflang', langCode);
        const alternatUrl = this.getUrlHost(langCode, url);
        this.alternatUrls.push(alternatUrl);
        this.renderer.setAttribute(linkElement, 'href', alternatUrl);
        this.insertElement(linkElement);
        this.linkElements.push(linkElement);

    }

    public updateAlternateHrefLangForBlogPosts(hrefLangMapping: HrefLangMapping) {
        if (hrefLangMapping) {
            languageCodes.forEach(langCode => {
                if (hrefLangMapping[langCode]) {
                    this.insertHrefLang(langCode, hrefLangMapping[langCode].href)
                }
            })
        }
    }

    /**
     * Some pages should not be indexed so we set the corresponding attribute
     */
    private handleNoIndexCase() {
        const currentUrl = window.location.href;
        const findIndex = this.alternatUrls.findIndex(
            (alternateUrl) => alternateUrl === currentUrl
        );
        if (-1 !== findIndex) {
            this.logger.log(
                ' Alternate Url found',
                findIndex,
                currentUrl,
                this.alternatUrls
            );
        } else {
            this.logger.log(
                'NOT Alternate Url found',
                findIndex,
                currentUrl,
                this.alternatUrls
            );
            this.logger.log('handleNoIndexCase')
            this.addNoIndex();
        }
    }

    private addNoIndex() {
        //        <meta name="robots" content="noindex">
        this.logger.log('addNoIndex', this.id);
        if (!this.noIndexElement) {
            // make sure we have only one noindex-element
            this.noIndexElement = this.renderer.createElement('meta');
            this.renderer.setAttribute(this.noIndexElement, 'name', 'robots');
            this.renderer.setAttribute(this.noIndexElement, 'content', 'noindex');
            this.insertElement(this.noIndexElement);
        }
    }

    private clearNoIndexElement() {
        if (this.noIndexElement) {
            this.renderer.removeChild(this.document.head, this.noIndexElement);
            this.noIndexElement = null;
        }
    }

    private insertElement(newElement) {
        const refElement = this.document.getElementById(
            'alternate-hreflang-placeholder'
        );
        if (refElement) {
            this.renderer.insertBefore(this.document.head, newElement, refElement);
        } else {
            this.renderer.appendChild(this.document.head, newElement);
        }
    }

    private getUrlHost(langCode: string, url: string): string {
        return environment.host_url + '/' + langCode + '/' + url;
    }

    private removeAlternateHrefLangs() {
        this.linkElements.forEach((linkElement) => {
            this.renderer.removeChild(this.document.head, linkElement);
        });
    }

    private updateHtmlTitle(id: string) {
        this.logger.log('updateHtmlTitle');
        this.htmlTitle.setTitle(this.trans.getSeoTitle(id));
    }

    private updateHtmlMetaDescription(id: string) {
        this.htmlMeta.updateTag({
            name: 'description',
            content: this.trans.getSeoDescription(id),
        });
    }
}
