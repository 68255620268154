import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslationDepricatedService } from '../shared/services/translation.depricated.service';
import { UIMesageService } from '../shared/services/ui-message.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
/**
 * Returns false if the user is not logged or if the user has
 *  no edit rights on ownerId that is provided as param from the ActivatedRouteSnapshot
 * otherwise redirect to login ot no-access page
 */
export class AuthGuard {
    constructor(
        private authService: AuthService,
        private uiMessage: UIMesageService,
        private logger: LoggerService,
        private router: Router,
        private userService: UserService,
        private trans: TranslationDepricatedService
    ) { }

    async canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean | UrlTree> {
        const isLoggedIn = await this.authService.getIsLoggedIn();
        if (!isLoggedIn) {
            this.logger.log('!isLoggedIn');
            return this.handleNotLoggedIn(state.url);
        }
        return this.handleLoggedIn(next);
    }

    private handleNotLoggedIn(url: string): UrlTree {
        this.logger.log('access denied; url: ', url);
        this.authService.navigateToUrl = url;
        // Redirect to the login page
        return this.router.parseUrl('user/login');
    }

    private async handleLoggedIn(
        next: ActivatedRouteSnapshot
    ): Promise<true | UrlTree> {
        this.logger.log('handleLoggedIn; next: ', next);
        const ownerId = next.params.ownerId;
        if (ownerId) {
            this.logger.log('Owner ID required ', ownerId);
            await this.userService.loadDbUser(this.authService.uid); // ?? this is not a beautifull solutiion but we have to make sure the user is loaded at this point
            if (this.authService.hasEditRights(this.authService.uid, ownerId)) {
                return true;
            } else {
                this.uiMessage.warning(this.trans.dic.noAccess);
                return this.router.parseUrl('user/no-access');
            }
        }
        return true;
    }
}
