import {
    Component,
    OnInit,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService } from 'ngx-essentia';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { NGXLogger as LoggerService } from "ngx-logger";
import { MatButtonModule } from '@angular/material/button';
import { FadeInAndOutComponent } from '../../../shared/fade-in-and-out/fade-in-and-out.component';
import { GoogleAnalyticsService } from '../../../shared/services/google-analytics.service';
import { RouterModule } from '@angular/router';
import { TranslationDepricatedService } from '../../../shared/services/translation.depricated.service';

@Component({
    selector: 'cookie-consent',
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        FadeInAndOutComponent

    ],
    templateUrl: './cookie-consent.component.html',
    styleUrls: ['./cookie-consent.component.scss'],
})
export class CookieConsentComponent implements OnInit {
    dialogRef: MatDialogRef<unknown>;

    constructor(
        public responsiveService: ResponsiveService,
        private logger: LoggerService,
        public dialog: MatDialog,
        private dialogService: EssDialogService,
        public googleAnalytics: GoogleAnalyticsService,
        public trans: TranslationDepricatedService,

    ) { }

    ngOnInit() {
    }

    label(key) {
        return this.trans.label('cookieConsent', key)
    }

    public openDialog(): void {
        this.dialogRef?.close(); // make sure we don't have multiple dialog open
        import('./cookie-consent-dialog/cookie-consent-dialog').then(
            (module) => {
                this.dialogRef = this.dialogService.open(
                    module.CookieConsentDialog,
                    {}
                );
            }
        );
    }
    public handleAgree() {
        this.googleAnalytics.setConsentGranted()

    }
}

