import { NGXLogger as LoggerService } from "ngx-logger";

import { AuthService } from "../../user/auth.service";
import { UIMesageService } from "../services/ui-message.service";
import { BannerInterface } from "./banner.interface";
import { TranslationDepricatedService } from "../services/translation.depricated.service";
import { GetAccessDialogComponent } from "../../whoo/shared/get-poll-access/accees-dialog/access-dialog.component";
import { EssDialogService } from 'ngx-essentia';
import { NavigationService } from "../../whoo/core/navigation.service";

export class AnonymousBanner implements BannerInterface {
    public priority = 101;
    public message: string;
    leftButtonLabel: string;
    rightButtonLabel: string;
    constructor(
        private logger: LoggerService,
        private authService: AuthService,
        private trans: TranslationDepricatedService,
        private dialogService: EssDialogService,
        private navigationService: NavigationService,
    ) {
        this.logger.log('AnonymousBanner');
        this.message = this.trans.label('anonymousBanner', 'message')
        this.leftButtonLabel = this.trans.label('anonymousBanner', 'leftButtonLabel')
        this.rightButtonLabel = this.trans.label('anonymousBanner', 'rightButtonLabel')
    }

    getShowBanner(): boolean {
        return this.authService.isAnonymous
    }

    public handleLeftButtonClick() {
        this.dialogService.open(GetAccessDialogComponent, null);

    }

    public handleRightButtonClick() {
        this.navigationService.navigate(this.trans.getRoutePath('upgradeAnonymous'))
    }
}

