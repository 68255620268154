import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService } from 'ngx-essentia';

import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { RouteContextService } from '../../../shared/services/route-context.service';
import { TranslationDepricatedService } from '../../../shared/services/translation.depricated.service';
import { AuthService } from '../../../user/auth.service';
import { UserService } from '../../../user/user.service';
import { NavigationService } from '../../../whoo/core/navigation.service';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'nav-create-poll-button',
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatIconModule
    ],
    templateUrl: './create-poll-button.component.html',
    styleUrls: ['./create-poll-button.component.scss'],
})
export class NavCreatePollButtonComponent {
    dialogRef: MatDialogRef<unknown>;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        private logger: LoggerService,
        public trans: TranslationDepricatedService,
        public navigationService: NavigationService,
        private dialogService: EssDialogService,
        public routeContextService: RouteContextService
    ) { }

    get disableCreatePollButton(): boolean {
        if (this.routeContextService.id === 'createPoll') {
            return true;
        }
        return false;
    }
    get color() {
        if (this.routeContextService.id === 'editPoll'
            || this.routeContextService.id === 'myPolls'
            || this.routeContextService.id === 'vote'

            || this.routeContextService.id === 'pollResponses'
        ) {
            return ""
        }
        return "warn"
    }

    openCreatePollDialog(): void {
        this.dialogRef?.close(); // make sure we don't have multiple dialog open
        import('../../../whoo/shared/poll/create-poll-dialog/create-poll-dialog').then(
            (module) => {
                this.dialogRef = this.dialogService.open(
                    module.CreatePollDialog,
                    null);
            }
        );
    }


}
