import { Injectable } from '@angular/core';
import { LOCALE_ID, Inject } from '@angular/core';


import routesEn from '../../assets/locale/en/routes.en.dic.json';
import routesDe from '../../assets/locale/en/routes.en.dic.json'; // @todo change to de
import routesFr from '../../assets/locale/en/routes.en.dic.json';
import routesEs from '../../assets/locale/en/routes.en.dic.json';
import routesPt from '../../assets/locale/en/routes.en.dic.json';
import routesIt from '../../assets/locale/en/routes.en.dic.json';

import { NGXLogger as LoggerService } from "ngx-logger";
import { Router } from '@angular/router';

const languageCodes = ["en", "de", "it", "fr", "es", "pt"]

const routesMap = [
    { langCode: "en", dictionary: routesEn },
    { langCode: "de", dictionary: routesDe },
    { langCode: "it", dictionary: routesIt },
    { langCode: "fr", dictionary: routesFr },
    { langCode: "es", dictionary: routesEs },
    { langCode: "pt", dictionary: routesPt },
]
@Injectable({
    providedIn: 'root'
})
export class AlternateHrefService {
    constructor(
        @Inject(LOCALE_ID) public locale: string,
        private logger: LoggerService,
        private router: Router,

    ) {
    }

    public getAlternateHrefLangs(pathId: string): { langCode: string, url: string }[] {
        let returnArray: { langCode: string, url: string }[] = []
        // translated route
        if (this.isTranslatedRoute(pathId)) {
            this.logger.log('isTranslatedRoute', pathId)
            routesMap.forEach(routes => {
                if (routes.dictionary.app[pathId]) {
                    returnArray.push({ langCode: routes.langCode, url: routes.dictionary.app[pathId] })
                }
            })
            //blog
        } else if (this.isBlogRoute(pathId)) {
            this.logger.log('isBlogRoute', pathId)
            // will be handled in the blog module
            //            returnArray = this.blogService.getAlternateHrefLangs();
            // all others
        } else {
            const url = this.router.url.replace("/", "")
            this.logger.log('else', pathId, url)
            languageCodes.forEach(languageCode => {
                returnArray.push({ langCode: languageCode, url: url });
            })
        }
        return returnArray
        // else
    }

    private isTranslatedRoute(pathId: string): boolean {
        return routesEn.app[pathId] ? true : false;
    }

    private isBlogRoute(pathId: string): boolean {
        return pathId === "blog-post" ? true : false;
    }



}
