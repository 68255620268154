import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { EssDialogService, TranslateLandingPagePipe } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { TranslationDepricatedService } from '../../../shared/services/translation.depricated.service';
import { TemplateService } from '../../../whoo/core/poll-templates/template.service';
import { TemplateKey } from '../../../whoo/core/poll-templates/template.configuration';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { CreatePollButton } from '../shared/create-poll-button/create-poll.button';
import { FounderStatementComponent } from '../shared/founder-statement/founder-statement';
import { HowItWorksItem } from '../shared/how-it-works-item/how-it-works-item';
import { TestemonialItem } from '../shared/testimonial-item/testimonial-item';
import { SectionTopComponent } from '../shared/section-top/section-top.component';
import { PollTemplatesComponent } from '../../../whoo/shared/poll-templates/poll-templates.component';
import { TemplateLabelPipe } from '../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { WhocanAdvantagesComponent } from '../shared/advantages/whocan-advantages.component';
import { WhyWhocanComponent } from '../shared/why-whocan/why-whocan.component';
import { HowItWorksComponent } from '../shared/how-it-works/how-it-works.component';
import { TestemonialStandalone } from '../shared/testimonial-standalone/testimonial-standalone';

@Component({
    templateUrl: './home.page.html',
    styleUrls: ['./home.page.scss'],

    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,

        // Standalone components
        CreatePollButton,
        FounderStatementComponent,
        HowItWorksItem,
        TestemonialItem,
        SectionTopComponent,
        WhocanAdvantagesComponent,
        PollTemplatesComponent,
        TemplateLabelPipe,
        WhyWhocanComponent,
        HowItWorksComponent,
        TestemonialStandalone,
        TranslateLandingPagePipe
    ],
})
export class HomePage {
    pollTitle = '';
    dialogRef: MatDialogRef<unknown>;
    templateKey = TemplateKey.advanced
    public howItWorksBigScreenImages = [
        "assets/images/how-it-works/choose-poll-type.gif",
        "assets/images/how-it-works/set-poll-options.gif",
        "assets/images/how-it-works/vote-on-poll.gif",
        "assets/images/how-it-works/poll-vote-responses.gif"
    ]
    public howItWorksSmallScreenImages = [
        "assets/images/how-it-works/mobile/choose-poll-type.gif",
        "assets/images/how-it-works/mobile/set-poll-options.gif",
        "assets/images/how-it-works/mobile/vote-on-poll.gif",
        "assets/images/how-it-works/mobile/poll-vote-responses.gif"
    ]
    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        public trans: TranslationDepricatedService,
        public templateService: TemplateService,
        private dialogService: EssDialogService
    ) { }

    createPoll(): void {
        import('../../../whoo/shared/poll/create-poll-dialog/create-poll-dialog').then(
            (module) => {
                this.logger.log('pollTitle: ', this.pollTitle);
                this.dialogRef?.close(); // make sure we don't have multiple dialog open
                this.dialogRef = this.dialogService.open(
                    module.CreatePollDialog,
                    {
                        pollTitle: this.pollTitle,
                    }

                );
            }
        );
    }

    get hideTemplates(): string[] {
        return ['advanced', 'rsvp',];
    }


}
