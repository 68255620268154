import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslationDepricatedService } from '../../../shared/services/translation.depricated.service';
import { templateConfigMap, TemplateKey } from './template.configuration';
import { UIMesageService } from '../../../shared/services/ui-message.service';
import pollTemplateDicEn from '../../../../assets/locale/en/templates.dic.en.json';
import pollTemplateDicEs from '../../../../assets/locale/es/templates.dic.es.json';
import pollTemplateDicPt from '../../../../assets/locale/pt/templates.dic.pt.json';
import pollTemplateDicFr from '../../../../assets/locale/fr/templates.dic.fr.json';
import pollTemplateDicIt from '../../../../assets/locale/it/templates.dic.it.json';
import pollTemplateDicDe from '../../../../assets/locale/de/templates.dic.de.json';
import defaultOptionsEn from '../../../../assets/locale/en/default-options.en.json';
import defaultOptionsDe from '../../../../assets/locale/de/default-options.de.json';
import defaultOptionsIt from '../../../../assets/locale/it/default-options.it.json';
import defaultOptionsFr from '../../../../assets/locale/fr/default-options.fr.json';
import defaultOptionsPt from '../../../../assets/locale/pt/default-options.pt.json';
import defaultOptionsEs from '../../../../assets/locale/es/default-options.es.json';

import { HeaderData, OptionConfigClass, OptionList, OptionListData } from 'whocan-lib';
import { EssErrorService } from 'ngx-essentia';
import { OptionData } from 'whocan-lib';
import { TranslocoService } from '@jsverse/transloco';
import { filter, firstValueFrom, from, map, Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
/**
 *
 */
export class TemplateService {
    private fallbackShowKeys = false
    public templateKey: string
    private getConfigAttributeCallCounter = 0
    private getDefaultSettingsCallCounter = 0
    public defaultOptions: any = null;

    constructor(
        private logger: LoggerService,
        private trans: TranslationDepricatedService,
        private uiMessage: UIMesageService,
        private errorService: EssErrorService,
        @Inject(LOCALE_ID) private locale: string,
        private transLoco: TranslocoService


    ) {
        this.initDefaultOptions()
    }



    public getTemplateHierarchy(templateKey: string): string[] {
        return this.getTemplateHierarchyReverse(templateKey).reverse()
    }

    private getTemplateHierarchyReverse(templateKey: string): string[] {
        let returnValue = templateKey ? [templateKey] : []
        const inheritFrom = this.getTemplateConfig(templateKey)?.general.inheritFrom
        this.logger.log('getTemplateHyrarchie:templateKey:inheritFrom', this.getTemplateConfig(templateKey), templateKey, inheritFrom)
        if (inheritFrom && inheritFrom !== templateKey) {
            this.logger.log('getTemplateHyrarchie:inheritFrom:returnValue', inheritFrom, returnValue)
            returnValue = [...returnValue, ...this.getTemplateHierarchyReverse(inheritFrom)] // recusrsive call with inherit from
        }
        this.logger.log('getTemplateHyrarchie:templateKey:returnValue', templateKey, returnValue)
        return (returnValue) // reverse the array to make it easy to pop
    }

    private initDefaultOptions() {
        if (this.locale === 'de') {
            this.defaultOptions = defaultOptionsDe
        } else if (this.locale === 'pt') {
            this.defaultOptions = defaultOptionsPt
        } else if (this.locale === 'it') {
            this.defaultOptions = defaultOptionsIt
        } else if (this.locale === 'fr') {
            this.defaultOptions = defaultOptionsFr
        } else if (this.locale === 'es') {
            this.defaultOptions = defaultOptionsEs
        } else {// the default is english
            this.defaultOptions = defaultOptionsEn
        }
    }

    private getDefaultOptionListData(defaultOptionsKey: string): OptionListData {
        let returnValue = this.defaultOptions[defaultOptionsKey]
        if (!returnValue) {
            returnValue = defaultOptionsEn[defaultOptionsKey];
        }
        return returnValue
    }

    /**
     *
     * @returns a deep copy of the default options
     */
    public getDefaultOptionList(): OptionList {
        let optionsData: OptionData[] | OptionListData;
        const defaultOptionsKey = <string>this.getConfigAttribute('general', 'defaultOptions');
        if (defaultOptionsKey) {
            optionsData = this.getDefaultOptionListData(defaultOptionsKey)
            this.logger.log('defaultOptionsData', optionsData)
        }
        return (new OptionList(optionsData)).deepCopy(); // need the deep copy to avoid changes made to the option would effect the next poll created
    }

    public initTemplate(templateKey: string) {
        // first reset the default
        this.templateKey = templateKey;
    }

    public getTemplateKeys(getAll: boolean = false): string[] {
        const returnValue: string[] = []
        templateConfigMap.forEach((config, key) => {
            if (getAll || config.general.show) {
                returnValue.push(<TemplateKey>key)

            }
        });
        return returnValue;
    }

    public getDefaultSettings(templateKey?: string): HeaderData {
        //      this.logger.log('getConfigAttribute start', attributeKeyLevel1, attributeKeyLevel2, templateKey)
        let templateKeyWork = templateKey;
        if (!templateKeyWork) {
            templateKeyWork = this.templateKey
        }
        if (!templateKeyWork) {
            templateKeyWork = TemplateKey.advanced
            this.logger.log('getDefaultSettings, template not initialized')
        }
        this.getDefaultSettingsCallCounter = 0
        try {
            const returnValue = this.getDefaultSettingsRecursive(templateKeyWork)
            return returnValue

        } catch (error) {
            this.logger.error(error)
            return
        }
    }


    private getDefaultSettingsRecursive(templateKey: string): HeaderData {
        const attributeKeyLevel1 = 'defaultSettings'
        this.getDefaultSettingsCallCounter++
        // make sure we are not in an endless recursive loop
        if (this.getDefaultSettingsCallCounter > 10) {
            this.errorService.newError('Error getting attribute ')

        }
        let returnValue = this.getTemplateConfig(templateKey)[attributeKeyLevel1]
        if (returnValue === undefined) {
            this.logger.log('getConfigAttributeRecursive; undefined', attributeKeyLevel1, templateKey)
            const inheritFrom = this.getTemplateConfig(templateKey)?.general.inheritFrom
            if (inheritFrom) {
                this.logger.log('getConfigAttributeRecursive; inheritFrom', attributeKeyLevel1, templateKey)
                returnValue = this.getDefaultSettingsRecursive(inheritFrom)
            }
            else {
                this.logger.log('getConfigAttributeRecursive; NOT inheritFrom', attributeKeyLevel1, templateKey)
                returnValue = this.getDefaultSettingsRecursive(TemplateKey.advanced)
            }
        }
        return returnValue
    }

    public getConfigAttribute(attributeKeyLevel1: string, attributeKeyLevel2: string, templateKey?: string): string | boolean | string[] {
        //      this.logger.log('getConfigAttribute start', attributeKeyLevel1, attributeKeyLevel2, templateKey)
        let templateKeyWork = templateKey;
        if (!templateKeyWork) {
            templateKeyWork = this.templateKey
        }
        if (!templateKeyWork) {
            templateKeyWork = TemplateKey.advanced
            this.logger.log('getConfigAttribute, template not initialized' + attributeKeyLevel1 + "/" + attributeKeyLevel2)
        }
        this.getConfigAttributeCallCounter = 0
        try {
            const returnValue = this.getConfigAttributeRecursive(attributeKeyLevel1, attributeKeyLevel2, templateKeyWork)
            return returnValue

        } catch (error) {
            this.logger.error(error)
            return ('')
        }
    }


    private getPollTemplatesDic(useEnglishDic?: boolean) {
        this.trans.locale
        let dic;
        if (useEnglishDic || this.trans.local === 'en') {
            dic = pollTemplateDicEn
        } else if (this.trans.local === 'pt') {
            dic = pollTemplateDicPt
        }
        else if (this.trans.local === 'es') {
            dic = pollTemplateDicEs
        }
        else if (this.trans.local === 'fr') {
            dic = pollTemplateDicFr
        }
        else if (this.trans.local === 'it') {
            dic = pollTemplateDicIt
        }
        else if (this.trans.local === 'de') {
            dic = pollTemplateDicDe
        } else {
            dic = pollTemplateDicEn
        }

        return dic
    }


    /**
     * get the label for a sppecific template attribute
     * Walks up the hierarchy of templates to get the label
     * Last try is always the english label for TemplateKey.advanced
     * @param attributeKeyLevel1
     * @param attributeKeyLevel2
     * @param templateKey If no templateKey it takes the tempale that is inittialezed,  fallback is  TemplateKey.advanced
     * @returns
     */
    public getLabel(attributeKeyLevel1: string, attributeKeyLevel2: string, templateKey?: string, useEnglishDic?: boolean): string {
        let templateKeyWork = templateKey;
        if (!templateKeyWork) {
            templateKeyWork = this.templateKey
        }
        if (!templateKeyWork) {
            templateKeyWork = TemplateKey.advanced
            this.logger.log('getConfigAttribute, template not initialized' + attributeKeyLevel1 + "/" + attributeKeyLevel2)
        }
        let dicUsersLan = this.getPollTemplatesDic(useEnglishDic);
        let returnValue = dicUsersLan[templateKeyWork]?.[attributeKeyLevel1]?.[attributeKeyLevel2]
        if (returnValue === undefined) {
            const inheritFrom = templateConfigMap.get(templateKeyWork)?.general.inheritFrom
            if (inheritFrom) {
                //                this.logger.log('getLabel; inheritFrom', attributeKeyLevel1, attributeKeyLevel2, templateKeyWork, useEnglishDic)
                returnValue = this.getLabel(attributeKeyLevel1, attributeKeyLevel2, inheritFrom, useEnglishDic)
            } else {
                //  this.logger.log('getLabel; NOT inheritFrom', attributeKeyLevel1, attributeKeyLevel2, templateKeyWork, useEnglishDic)
                if (templateKeyWork !== TemplateKey.advanced) {
                    returnValue = this.getLabel(attributeKeyLevel1, attributeKeyLevel2, TemplateKey.advanced, useEnglishDic)
                }
                if (returnValue == undefined) {
                    // try now with the english dictionary  dicEn
                    if (!useEnglishDic && dicUsersLan !== this.getPollTemplatesDic(true)) {
                        returnValue = this.getLabel(attributeKeyLevel1, attributeKeyLevel2, TemplateKey.advanced, true)
                    }
                    else {
                        if (this.fallbackShowKeys) {
                            returnValue = attributeKeyLevel1 + "/" + attributeKeyLevel2
                        } else {
                            returnValue = ""
                        }
                    }
                }
            }
        }
        return returnValue
    }

    private getConfigAttributeRecursive(attributeKeyLevel1: string, attributeKeyLevel2: string, templateKey: string): string | boolean {
        this.getConfigAttributeCallCounter++
        // make sure we are not in an endless recursive loop
        if (this.getConfigAttributeCallCounter > 10) {
            const errorMessage = ('Error getting attribute: ' + templateKey + "/" + attributeKeyLevel1 + "/" + attributeKeyLevel2)
            throw (new Error(errorMessage))
        }
        let returnValue = this.getTemplateConfig(templateKey)[attributeKeyLevel1]?.[attributeKeyLevel2]
        if (returnValue === undefined) {
            const inheritFrom = this.getTemplateConfig(templateKey)?.general.inheritFrom
            if (inheritFrom) {
                returnValue = this.getConfigAttributeRecursive(attributeKeyLevel1, attributeKeyLevel2, inheritFrom)
            }
            else {
                this.logger.log('getConfigAttributeRecursive; NOT inheritFrom', attributeKeyLevel1, attributeKeyLevel2, templateKey)
                returnValue = this.getConfigAttributeRecursive(attributeKeyLevel1, attributeKeyLevel2, TemplateKey.advanced)
            }
        }
        return returnValue
    }

    private getTemplateConfig(templateKey: string) {
        let returnValue = templateConfigMap.get(templateKey)
        if (returnValue === undefined || returnValue === null) {
            returnValue = templateConfigMap.get(TemplateKey.advancedNew)
        }
        return returnValue

    }

    /**
     *  make sure the template that we goot is valid and if not set template to advanced
     * @param templateFromRoute
     * @returns
     */
    public getPollTemplate(templateFromRoute: string): string {
        let templateKey: string;
        templateConfigMap.forEach((config, key) => {
            if (key === templateFromRoute) {
                templateKey = <TemplateKey>key;
            }
        })
        if (templateKey) {
            return templateKey
        } else {
            return TemplateKey.advanced
        }
    }

    public getIcon(templateKey?: string): string {
        return <string>this.getConfigAttribute('general', 'icon', templateKey)
    }

    getOptionConfigKeys(filterAllowToAddOptions: boolean = false): string[] {
        let returnValue = []
        let optionTypes = this.getConfigAttribute('options', 'optionTypes')
        if (Array.isArray(optionTypes)) {
            returnValue = <string[]>optionTypes
        }
        if (filterAllowToAddOptions) {
            returnValue = returnValue.filter((optionConfigKey) => {
                const optionConfig = OptionConfigClass.newOptionConfigClass(optionConfigKey)
                if (!optionConfig) throw (this.errorService.newError('getOptionConfigKeys - no such optionConfig' + optionConfigKey))
                return optionConfig.allowToAddOptions
            })
        }
        return returnValue
    }

    getDateOptionConfigKeys(filterAllowToAddOptions: boolean = false): string[] {
        let returnValue = this.getOptionConfigKeys(filterAllowToAddOptions)
        returnValue = returnValue.filter(optionConfigKey => {
            const optionConfig = OptionConfigClass.newOptionConfigClass(optionConfigKey)
            if (!optionConfig) throw (this.errorService.newError('getDateOptionConfigKeys - no such optionConfig' + optionConfigKey))
            return optionConfig.isOfTypeDate
        })
        return returnValue
    }


    getOptionConfigKeyLabel(configKey: string): string {
        let returnValue = this.getLabel('configurableOption', configKey + '_buttonLabel')
        if (!returnValue) {
            this.logger.error('No Button Label for optionType: ' + configKey + '_buttonLabel')
            returnValue = returnValue
        }
        return returnValue
    }
}
