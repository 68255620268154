import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { TopBackgroundComponent } from '../top-background/top-background.component';
import { PollTemplatesComponent } from '../../../../whoo/shared/poll-templates/poll-templates.component';
import { TestemonialItem } from '../testimonial-item/testimonial-item';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { TemplateKey } from '../../../../whoo/core/poll-templates/template.configuration';
import { TemplateService } from '../../../../whoo/core/poll-templates/template.service';
import { TemplateLabelPipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { NGXLogger as LoggerService } from "ngx-logger";
import { TranslationComponent, TranslateLandingPagePipe, TranslatePipe } from 'ngx-essentia';


@Component({
    templateUrl: './section-top.component.html',

    selector: 'landing-page-section-top',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,

        // Standalone components
        CreatePollButton,
        TestemonialItem,
        PollTemplatesComponent,
        TopBackgroundComponent,
        TranslateLandingPagePipe,
    ],
    styleUrls: ['./section-top.component.scss'],
})
export class SectionTopComponent implements OnInit {
    @Input() templateKey: string;
    @Input() labelKey = "landingPageSectionTop"
    @Input() imageSource = "assets/images/lady-blue-shirt-pointing2.webp"
    @Input() useStandardContent = true;

    constructor(
        public responsiveService: ResponsiveService,
        public templateService: TemplateService,
        private logger: LoggerService,

    ) {
    }
    async ngOnInit(): Promise<void> {
    }

    get labelPrefix() {
        return this.templateKey + '.' + this.labelKey + '.'
    }

}
