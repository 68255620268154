import { Component, Input } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';
import { TemplateLabelPipe } from '../../../../whoo/core/poll-templates/template-label/template-label.pipe';
import { HowItWorksItem } from '../how-it-works-item/how-it-works-item';
import { CreatePollButton } from '../create-poll-button/create-poll.button';
import { TemplateService } from '../../../../whoo/core/poll-templates/template.service';
import { EssTranslationService, TranslateLandingPagePipe } from 'ngx-essentia';

@Component({
    selector: 'how-it-works',
    standalone: true,
    imports: [
        CommonModule,
        MatDividerModule,
        RouterModule,
        MatIconModule,
        // Standalone components
        HowItWorksItem,
        CreatePollButton,
        TranslateLandingPagePipe
    ],

    templateUrl: './how-it-works.component.html',
    styleUrls: ['./how-it-works.component.scss'],
})
export class HowItWorksComponent {
    @Input() showCreatePollButton: boolean = false;
    @Input() templateKey: string;
    @Input() bigScreenImages: string[];
    @Input() smallScreenImages: string[]
    @Input() numberOfItems: number = 4;

    constructor(
        private logger: LoggerService,
        public responsiveService: ResponsiveService,
        private trans: EssTranslationService

    ) { }

    public getBigScreenImage(index: number) {
        //  this.logger.log('getBigScreenImage', this.templateService.getLabel('landingPageHowItWorks', 'image-1', this.templateKey), this.templateKey)
        if (this.bigScreenImages && this.bigScreenImages[index - 1]) {
            return this.bigScreenImages[index - 1]
        }
        return ""

    }


    getSmallScreenImage(index: number): string {
        if (this.smallScreenImages && this.smallScreenImages[index - 1]) {
            return this.smallScreenImages[index - 1]
        } else {
            return null
        }
    }

    get labelPrefix() {
        return this.templateKey + '.landingPageHowItWorks.'
    }
}
