import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { environment } from '../../../environments/environment';
import { NGXLogger as LoggerService } from "ngx-logger";

import { ResponsiveService } from '../../shared/services/responsive.service';
import { TranslationDepricatedService } from '../services/translation.depricated.service';
import { AuthService } from '../../user/auth.service';
import { UserService } from '../../user/user.service';
import { NavigationService } from '../../whoo/core/navigation.service';



@Component({
    selector: 'logo-component',

    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],

    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        public trans: TranslationDepricatedService,
        private logger: LoggerService,
        public navigationService: NavigationService,
    ) { }




    public getHomeButtonName(): string {
        return environment.home_button_label_short;
    }
}
