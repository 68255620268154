import { APP_ID, NgModule } from '@angular/core';

// FIREBASE MODULES
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import {
    Auth,
    browserLocalPersistence,
    browserSessionPersistence,
    indexedDBLocalPersistence,
    initializeAuth,
    provideAuth,
} from '@angular/fire/auth';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { getDatabase, provideDatabase } from '@angular/fire/database';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';

import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

// MATERIAL MODULES
import { MatTooltipModule } from '@angular/material/tooltip';

// THIRD PARTY MODULES
import { NgxEssentiaModule, NgxLoggerCustomServerService } from 'ngx-essentia';
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from "ngx-logger";


// APP MODULES AND COMPONENTS
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AlertComponent } from './shared/alert/alert.component';
import { Error404Page } from './app-shell/errors/404.component';
import { FooterComponent } from './app-shell/footer/footer.component';
import { CreatePollButton } from './app-shell/landing-pages/shared/create-poll-button/create-poll.button';
import { FounderStatementComponent } from './app-shell/landing-pages/shared/founder-statement/founder-statement';
import { HowItWorksItem } from './app-shell/landing-pages/shared/how-it-works-item/how-it-works-item';
import { TestemonialItem } from './app-shell/landing-pages/shared/testimonial-item/testimonial-item';
import { NavBarComponent } from './app-shell/nav/nav-bar/nav-bar.component';
import { NavMenuButtonComponent } from './shared/menu-button/menu-button.component';
import { appRoutes } from './routes';
import { LogoComponent } from './shared/logo/logo.component';
import { LanguageCheck } from './app-shell/shared/language-check/language-check.component';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { CustomDateAdapter } from './shared/helpers/custom-date-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { WhooAppComponent } from './whoo/app-component/whoo-app.component';
import { FeedbackButtonComponent } from './shared/feedback/feedback-button.component';
import { StickyBarComponent } from './shared/sticky-bar/sticky-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { HttpClientModule, provideHttpClient } from '@angular/common/http';
import { CookieConsentComponent } from './app-shell/shared/cookie-consent/cookie-consent.component';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { provideTransloco } from '@jsverse/transloco';
import { TranslocoHttpLoader } from './shared/transloco-loader/transloco-http-loader';


@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forRoot(appRoutes, {
            scrollPositionRestoration: 'enabled',
        }),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideAuth(() => {
            const auth: Auth = initializeAuth(getApp(), {
                persistence: [
                    indexedDBLocalPersistence,
                    browserLocalPersistence,
                    browserSessionPersistence,
                ],
                popupRedirectResolver: undefined,
            });
            return auth;
        }),
        provideFirestore(() => getFirestore()),
        provideDatabase(() => getDatabase()),
        MatTooltipModule,
        BrowserAnimationsModule,

        MarkdownModule.forRoot(),
        HttpClientModule,

        NgxEssentiaModule.init({
            isDebug: environment.debug_log,
            loggerPrefix: 'WHOCAN-LIB',
            appName: environment.brandName,
            version: environment.appVersion,
            host: environment.host
        }),
        LoggerModule.forRoot({
            serverLoggingUrl: '/uilogging',
            level: environment.ngxLoggerLevel,
            serverLogLevel: environment.ngxServerLoggerLevel,
            enableSourceMaps: true
        },
            {
                serverProvider: {
                    provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: NgxLoggerCustomServerService
                }
            }
        ),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            // Register the ServiceWorker immediatly because the angular standard "registerWhenStable:30000"
            // seems not to work with lighthaouse
            // getting error "Es wurde kein Service Worker erkannt, der die Seite und start_url kontrolliert"
            registrationStrategy: 'registerImmediately',
        }),
        // STANDALONE COMPONENTS
        AlertComponent,
        CreatePollButton,
        FounderStatementComponent,
        HowItWorksItem,
        TestemonialItem,
        NavMenuButtonComponent,
        LogoComponent,
        LanguageCheck,
        WhooAppComponent,
        NavBarComponent,
        FeedbackButtonComponent,
        StickyBarComponent,
        FooterComponent,
        CookieConsentComponent

    ],
    declarations: [
        // MAIN COMPONENT
        AppComponent,
        // 404 NOT FOUND
        Error404Page,
        // INDEX PAGE
    ],
    bootstrap: [AppComponent],
    providers:
        [

            provideHttpClient(),
            provideTransloco({
                config: {
                    availableLangs: ['en', 'de', 'fr', 'it', 'es', 'pt'],
                    defaultLang: 'en',
                    fallbackLang: 'en',
                    reRenderOnLangChange: false,
                    prodMode: environment.production,
                    missingHandler: {
                        allowEmpty: true,
                        logMissingKey: false,
                        useFallbackTranslation: true,
                    },
                    scopes: {
                        keepCasing: true
                    }
                },
                loader: TranslocoHttpLoader,
            }),
            {
                provide: FIREBASE_OPTIONS,
                useValue: environment.firebase,
            },
            {
                provide: MAT_RADIO_DEFAULT_OPTIONS,
                useValue: { color: 'primary' },
            },
            {
                provide: MAT_DATE_LOCALE,
                useValue: 'en-GB',
            },
            {
                provide: APP_ID,
                useValue: 'serverApp',
            },
            // https://github.com/angular/angularfire/blob/master/docs/compat/emulators/emulators.md
            { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },// https://www.reddit.com/r/Angular2/comments/piiwvc/angular_not_working_with_firebase_emulators/
            { provide: USE_DATABASE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 9001] : undefined },
            //  { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
            { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
        ],
})
export class AppModule { }
