<div class="background-top-container">
    <div class="background-top">
    </div>
</div>

<div class="whoo-section-container section-container">
    <h2>
        {{labelPrefix + 'headline'| transPage | async }}
    </h2>
    <div>
        <div class="how-it-works-item-container">
            <how-it-works-item title="{{labelPrefix + 'title-1'| transPage | async }}"
                [imageUrlBigScreen]="getBigScreenImage(1)" [imageUrlSmallScreen]="getSmallScreenImage(1)"
                description="{{labelPrefix + 'description-1'| transPage | async }}" [imageRight]="false">
            </how-it-works-item>
        </div>
        <div class="how-it-works-item-container">
            <how-it-works-item *ngIf="numberOfItems >1" title="{{labelPrefix + 'title-2'| transPage | async }}"
                [imageUrlBigScreen]="getBigScreenImage(2)" [imageUrlSmallScreen]="getSmallScreenImage(2)"
                description="{{labelPrefix + 'description-2'| transPage | async }}" [imageRight]="true">
            </how-it-works-item>
        </div>
        <div class="how-it-works-item-container">
            <how-it-works-item *ngIf="numberOfItems >2" title="{{labelPrefix + 'title-3'| transPage | async }}"
                [imageUrlBigScreen]="getBigScreenImage(3)" [imageUrlSmallScreen]="getSmallScreenImage(3)"
                description="{{labelPrefix + 'description-3'| transPage | async }}" [imageRight]="false">
            </how-it-works-item>
        </div>
        <div class="how-it-works-item-container">
            <how-it-works-item *ngIf="numberOfItems >3" title="{{labelPrefix + 'title-4'| transPage | async }}"
                [imageUrlBigScreen]="getBigScreenImage(4)" [imageUrlSmallScreen]="getSmallScreenImage(4)"
                description="{{labelPrefix + 'description-4'| transPage | async }}" [imageRight]="true">
            </how-it-works-item>
        </div>
        <div class="how-it-works-item-container">
            <how-it-works-item *ngIf="numberOfItems >4" title="{{labelPrefix + 'title-5'| transPage | async }}"
                [imageUrlBigScreen]="getBigScreenImage(5)" [imageUrlSmallScreen]="getSmallScreenImage(5)"
                description="{{labelPrefix + 'description-5'| transPage | async }}" [imageRight]="false">
            </how-it-works-item>
        </div>
        <div class="how-it-works-item-container">
            <how-it-works-item *ngIf="numberOfItems >5" title="{{labelPrefix + 'title-6'| transPage | async }}"
                [imageUrlBigScreen]="getBigScreenImage(6)" [imageUrlSmallScreen]="getSmallScreenImage(6)"
                description="{{labelPrefix + 'description-6'| transPage | async }}" [imageRight]="true">
            </how-it-works-item>
        </div>

    </div>
</div>
<div class="background-bottom-container">
    <div class="background-bottom">
    </div>
</div>
<div *ngIf="showCreatePollButton" class="whoo-centered-container-fit-content create-poll">
    <create-poll-button [pollTemplateKey]="templateKey"
        titleFieldLabel="{{labelPrefix + 'fieldLabel'| transPage | async }}"
        titleFieldLabelShort="{{labelPrefix + 'fieldLabelShort'| transPage | async }}"
        buttonLabel="{{labelPrefix + 'buttonLabel'| transPage | async }}"
        buttonLabelShort="{{labelPrefix + 'buttonLabelShort'| transPage | async }}">
    </create-poll-button>
</div>