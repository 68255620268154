import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger as LoggerService } from "ngx-logger";
import { EssTranslationService } from './translation.service';

/**
 * usage:   <trans keys="home.testXXX" scope="landing-pages" [template]="templateKey"></trans>
 * <trans keys="logout.success" scope="user"></trans>
 */

@Component({
    selector: 'trans',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './translation.component.html',
})
export class TranslationComponent implements OnInit {
    @Input() keys: string;
    @Input() scope: string;
    @Input() templateHierarchy: string[];
    translation: string = "";
    constructor(
        public essTrans: EssTranslationService,
        private logger: LoggerService,

    ) { }
    async ngOnInit(): Promise<void> {
        this.translation = await this.essTrans.translate(this.keys, this.scope, this.templateHierarchy)
    }

}
