import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { NgxEssentiaModule } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";
import { ResponsiveService } from '../../../../shared/services/responsive.service';
import { CurrentPollService } from '../../../core/current-poll.service';
import { GetPollAccessComponent } from '../get-poll-access.component';
import { TranslationDepricatedService } from '../../../../shared/services/translation.depricated.service';

@Component({
    standalone: true,
    imports: [
        MatDialogModule,
        CommonModule,
        NgxEssentiaModule,
        GetPollAccessComponent

    ],
    templateUrl: 'access-dialog.component.html',
    styleUrls: ['access-dialog.component.scss'],
})
export class GetAccessDialogComponent {
    constructor(
        public logger: LoggerService,
        public responsiveService: ResponsiveService,
        public dialogRef: MatDialogRef<GetAccessDialogComponent>,
        public dialog: MatDialog,
        public currentPollService: CurrentPollService,
        public trans: TranslationDepricatedService,

    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
