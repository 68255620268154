import { inject, Injectable } from '@angular/core';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { NGXLogger as LoggerService } from "ngx-logger";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    constructor(
        private logger: LoggerService,
        private http: HttpClient
    ) {
    }

    getTranslation(scopeSlashLang: string) {
        scopeSlashLang = scopeSlashLang.toLowerCase()
        const scopeArray = this.getScope(scopeSlashLang)
        const lang = this.getLang(scopeSlashLang)
        //        this.logger.warn('getTranslation langSlashScope: ', scopeSlashLang, scopeArray, lang)
        if (scopeArray) {
            const scopeString = scopeArray.join('.')
            return this.http.get<Translation>(`/assets/locale/${lang}/${scopeArray[0]}/${scopeString}.${lang}.dic.json`);
        } else {
            return this.http.get<Translation>(`/assets/locale/${lang}/default.${lang}.dic.json`);
        }
    }

    /**
     *
     * @param scopeSlashLang  a string like "landing-pages/schedule/en"  or  "landing-pages/en" also just the language key  for example "en"
     * @returns the scope - in the example above, ['templates', 'schedule'], ['landing-pages', 'schedule'] or  ['landing-pages'] if there is no scope but just the language key (which is always the string behind the last /), return null
     */
    getScope(scopeSlashLang: string): string[] | null {
        const parts = scopeSlashLang.split('/');
        if (parts.length > 1) {
            return parts.slice(0, -1);
        }
        return null;
    }

    /**
     *
     * @param scopeSlashLang  a string like "landing-pages/schedule/en"  or  "landing-pages/en" also just the language key  for example "en"
     * @returns the language key  (which is always the string behind the last /) - in the examples above "en"
     */
    getLang(scopeSlashLang: string): string {
        const parts = scopeSlashLang.split('/');
        return parts[parts.length - 1];
    }
}
