<div [ngClass]="responsiveService.getBigSmall()">
    <div class="whoo-left-right context-bar-container">
        <div class="left context-bar-item">
            <button mat-button class="navigation-button" (click)="navigateBack()">
                <mat-icon class="icon">navigate_before</mat-icon>
                <span *ngIf="routeContextService.title && responsiveService.isScreenBig()">{{trans.label('actionLabels',
                    'back')}}</span>
            </button>

        </div>
        <div *ngIf="routeContextService.title" class="middle context-bar-item">
            <span>{{routeContextService.title}}</span>
        </div>
        <span class="right context-bar-item">
            <bdc-walk-popup #hintSignup [mustCompleted]="{hintEdit: true}" xPosition="before" [showCloseButton]="false"
                [showButton]="true" class="whoo-bdc-walk-popup whoo-bdc-walk-popup-mobile-right"
                buttonText="{{'vote.hintStandardButton' | templateLabel}}" [alignCenter]="false" name="hintSignup"
                header=" {{'vote.hintSignup' | templateLabel}}" [arrow]="true">
                {{'vote.hintSignupDetails' | templateLabel}}
            </bdc-walk-popup>



            <!--BIG SCREEN-->
            <span *ngIf=" !responsiveService.isScreenSmall()" class="button-bar">

                <!--share-->
                <button mat-icon-button color="primary" [matMenuTriggerFor]="shareMenu"
                    matTooltip="{{trans.label('actionLabels','share' )}}">
                    <mat-icon class="icon">share</mat-icon>
                </button>
                <mat-menu #shareMenu="matMenu">
                    <span>
                        <!--shareEvent-->
                        <button mat-menu-item (click)="sharePoll()">
                            <span>{{trans.label('actionLabels','shareEvent' )}}</span>
                        </button>
                        <!--shareResultMatrix-->
                        <button mat-menu-item (click)="shareResult()">
                            <span>{{trans.label('actionLabels','shareResultMatrix' )}}</span>
                        </button>
                    </span>
                </mat-menu>

                <!--navigate editPoll-->
                <button *ngIf="routeContextService.id !=='editPoll' && routeContextService.id !=='votePreview' "
                    mat-icon-button (click)="editPoll()" matTooltip="{{'actionLabels.edit' | transApp  | async}}"
                    aria-label="Edit">
                    <mat-icon class="icon">edit_outline</mat-icon>
                </button>

                <!--open sidebar to edit-->
                <button *ngIf="routeContextService.id ==='votePreview'  || routeContextService.id ==='editPoll'"
                    mat-icon-button color="primary" matTooltip="{{trans.label('actionLabels','edit' )}}"
                    aria-label="Edit" (click)="contextActionBarService.handleClickEdit()">
                    <mat-icon class="icon">edit</mat-icon>
                </button>

                <!--add option-->
                <add-option-button *ngIf="routeContextService.id ==='votePreview'" appearance="icon" color="primary"
                    (onAddOption)="contextActionBarService.handleClickAddOption($event)"></add-option-button>

                <!--delete-->
                <button mat-icon-button color="primary" (click)="deletePoll()"
                    matTooltip="{{trans.label('actionLabels','delete' )}}">
                    <mat-icon class="icon">delete_outline</mat-icon>
                </button>

                <!--personalAccess-->
                <button mat-icon-button *ngIf="authService.isLoggedIn  && authService.isAnonymous" color="primary"
                    (click)="getAccessDialog()" matTooltip="{{trans.label('actionLabels','personalAccess' )}}"
                    [bdcWalkTriggerFor]="hintSignup">
                    <mat-icon class="icon">key</mat-icon>
                </button>

                <!--downloadCalendarEntries-->
                <button mat-icon-button color="primary" (click)="handleClickCalendar()"
                    matTooltip="{{trans.label('actionLabels','downloadCalendarEntries' )}}">
                    <mat-icon class="icon">calendar_today</mat-icon>
                </button>


                <!--pollResponses-->
                <button *ngIf="routeContextService.id !=='pollResponses'" mat-icon-button color="primary"
                    (click)="openPollOverview()" matTooltip="{{trans.label('actionLabels','dasboard' )}}">
                    <mat-icon class="icon">bar_chart_4_bars</mat-icon>
                </button>

                <!--preview-->
                <button *ngIf="routeContextService.id !=='votePreview'" mat-icon-button color="primary"
                    (click)="openVotePage()" matTooltip="{{trans.label('actionLabels','preview' )}}">
                    <mat-icon class="icon">preview</mat-icon>
                </button>

                <!--home-->
                <button mat-icon-button color="primary" (click)="navigationService.navigateMyPolls()"
                    matTooltip="{{trans.label('actionLabels','home')}}" aria-label=" Home">
                    <mat-icon class="icon">home</mat-icon>
                </button>
            </span>




            <!--SMALL SCREEN-->
            <ng-container *ngIf="responsiveService.isScreenSmall()">
                <!--add option-->
                <add-option-button *ngIf="routeContextService.id ==='votePreview'" appearance="icon" color="primary"
                    (onAddOption)="contextActionBarService.handleClickAddOption($event)"></add-option-button>
                <span class="menu-button">
                    <button mat-icon-button color="primary" [matMenuTriggerFor]="menu">
                        <mat-icon color="primary">more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <span>

                            <!--shareEvent-->
                            <button mat-menu-item (click)="sharePoll()">
                                <mat-icon>share</mat-icon>
                                <span>{{trans.label('actionLabels','shareEvent' )}}</span>
                            </button>

                            <!--shareResultMatrix-->
                            <button mat-menu-item (click)="shareResult()">
                                <mat-icon>share</mat-icon>
                                <span>{{trans.label('actionLabels','shareResultMatrix' )}}</span>
                            </button>


                            <!--personalAccess-->
                            <button mat-menu-item *ngIf="authService.isLoggedIn  && authService.isAnonymous"
                                (click)="getAccessDialog()">
                                <mat-icon>login</mat-icon>
                                <span>{{trans.label('actionLabels','personalAccess' )}}</span>
                            </button>



                            <!--navigate editPoll-->
                            <button mat-menu-item (click)="editPoll()"
                                *ngIf="routeContextService.id !=='editPoll'  && routeContextService.id !=='votePreview'">
                                <mat-icon>edit</mat-icon>
                                <span>{{trans.label('actionLabels','edit' )}}</span>
                            </button>

                            <!--open sideNav to edit-->
                            <button mat-menu-item *ngIf="routeContextService.id ==='votePreview'"
                                (click)="contextActionBarService.handleClickEdit()">
                                <mat-icon>edit</mat-icon>
                                <span>{{trans.label('actionLabels','edit' )}}</span>
                            </button>

                            <!--delete-->
                            <button mat-menu-item (click)="deletePoll()">
                                <mat-icon>delete</mat-icon>
                                <span>{{trans.label('actionLabels','delete' )}}</span>
                            </button>

                            <!--downloadCalendarEntries-->
                            <button mat-menu-item (click)="handleClickCalendar()">
                                <mat-icon>calendar_today</mat-icon>
                                <span>{{trans.label('actionLabels','downloadCalendarEntries' )}}</span>
                            </button>

                            <!--dashboard-->
                            <button mat-menu-item (click)="openPollOverview()"
                                *ngIf="routeContextService.id !=='pollResponses'">
                                <mat-icon>bar_chart_4_bars</mat-icon>
                                <span>{{trans.label('actionLabels','dasboard' )}}</span>
                            </button>

                            <!--preview-->
                            <button mat-menu-item (click)="openVotePage()"
                                *ngIf="routeContextService.id !=='votePreview'">
                                <mat-icon>preview</mat-icon>
                                <span>{{trans.label('actionLabels','preview' )}}</span>
                            </button>

                            <!--home-->
                            <button mat-menu-item (click)="navigationService.navigateMyPolls()">
                                <mat-icon>home</mat-icon>
                                <span>{{trans.label('actionLabels','home' )}}</span>
                            </button>


                        </span>
                    </mat-menu>
                </span>
            </ng-container>

        </span>
    </div>
</div>