import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';

import { EssDialogService } from 'ngx-essentia';
import { NGXLogger as LoggerService } from "ngx-logger";

import { ResponsiveService } from '../services/responsive.service';
import { TranslationDepricatedService } from '../services/translation.depricated.service';
import { UIMesageService } from '../services/ui-message.service';
import { AuthService } from '../../user/auth.service';
import { UserService } from '../../user/user.service';
import { NavigationService } from '../../whoo/core/navigation.service';
import { PollService } from '../../whoo/core/poll.service';
import { AuthErrorService } from '../../user/auth-error.service';

export interface DialogData {
    animal: string;
    name: string;
}

@Component({
    selector: 'nav-menu-button',

    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        MatMenuModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
    ],

    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
})
export class NavMenuButtonComponent {
    @Input() appearance: 'fullScreen' | 'navBar' = 'navBar';
    @Input() themeType: 'dark' | 'light' = 'light';
    public qrCode: string = null;
    dialogRef: MatDialogRef<unknown>;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public responsiveService: ResponsiveService,
        public dialog: MatDialog,
        public trans: TranslationDepricatedService,
        private dialogService: EssDialogService,
        private logger: LoggerService,
        public navigationService: NavigationService,
        private uiMessage: UIMesageService,
        public pollService: PollService,
        private authErrorService: AuthErrorService,

    ) { }

    openCreatePollDialog(): void {
        import('../../whoo/shared/poll/create-poll-dialog/create-poll-dialog').then(
            (module) => {
                this.dialogRef?.close(); // make sure we don't have multiple dialog open
                this.dialogRef = this.dialogService.open(
                    module.CreatePollDialog,
                    null);
            }
        );
    }


    async logout() {
        try {
            this.logger.log('logout');
            this.navigationService.navigate(this.trans.getRoutePath('index'));
            this.pollService.unInitializePoll();
            await this.authService.logOut();
            this.uiMessage.success(this.trans.dic.logout.success);
        } catch (error) {
            this.authErrorService.handleError(error)

        }
    }
}
